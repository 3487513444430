import { ddLoginGetCode } from "./ddLoginGetCode";
import { ddLogin } from "./ddLogin";
import { ddLoginDev } from "./ddLoginDev";
import webLogin from './webLogin'
import {useRouter} from 'vue-router'
import {nextTick} from 'vue'
import {useUserInfoStore} from '@/stores/userInfoStore'


export default async () => {
    const userInfoStore = useUserInfoStore()
    if(userInfoStore.info.name) return
    if(!process.client)return
    console.log('client login')
        
    await webLogin()
}
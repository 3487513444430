export const useCurrCustomerTypeStore = definePiniaStore('currCustomerType', () => {
    
    const localCustomerType = process.client ? JSON.parse(localStorage.getItem('localCustomerType') || 'null')  : ""
    
    const value = ref(localCustomerType || "")
    
    watch(()=>value.value, ()=>{
        if(process.client){
            localStorage.setItem('localCustomerType', JSON.stringify(value.value))
        }
    })


    const logOut = ()=>{
        value.value = ''
    }

    return {
        value,
        logOut
    }
})

import {useRouter} from 'vue-router'
import {useUserInfoStore} from '@/stores/userInfoStore'

export default async () => {
    const userInfoStore = useUserInfoStore()
    if(userInfoStore.info.name) return
    const urlSearchParams = new URLSearchParams(window.location.search)
    const entries = Object.fromEntries(urlSearchParams.entries());
    const router = useRouter()
    
    if(!entries.code){
        if(router){
            router.push('/login')
        }
        return
    }
    try{
        const res = await tFetch(
            '/api/account/login',
            {
                method: 'POST', 
                body:{ code: entries.code }
            }
        )
    
        if(res.status === 200){
            console.log('web login res', res)
            const {userInfo, jwt, timestamp} = res.payload;
            document.cookie = `jwt=${jwt};`
            userInfoStore.info = userInfo
            userInfoStore.timestamp = timestamp
        }else{
            console.log('failed web login', res)
            if(router){
                router.push('/login')
            }
        }
    }catch(e){
        console.log('webLogin Error', e)     
        if(router){
            router.push('/login')
        }  
    }

}